import logo from './logo.svg';
import './App.css';
import Home from './pages/Home.js';
import ReadPosts from './pages/ReadPosts.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="update" element={<ReadPosts />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { initializeApp } from 'firebase/app';
import { collection, addDoc, QuerySnapshot, query, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import Card from "../componenets/Card";

const app_hostname = window.location.hostname === "localhost" ? "social.unpr.6lab.pro" : window.location.hostname;
const Home = () => {
    const [media, setmedia] = useState([]);
    const [activePlatform, setActivePlatform] = useState({});

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const mediaCollection = collection(db, "media");
                const mediaSnapshot = await getDocs(mediaCollection);
                const mediaList = mediaSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), post_url: `https://${app_hostname}/s/${doc.id}` }));
                setmedia(mediaList);

                const initialActivePlatform = {};
                mediaList.forEach(campaign => {
                    if (campaign.platforms && campaign.platforms.length > 0) {
                        initialActivePlatform[campaign.id] = campaign.platforms[0];
                    }
                });
                setActivePlatform(initialActivePlatform);
                console.log(initialActivePlatform);

            } catch (error) {
                console.error("Error fetching media: ", error);
            }
        }
        fetchCampaign();
    }, []);

    const handlePlatformClick = (campaignId, platform) => {
        setActivePlatform(prevState => ({
            ...prevState,
            [campaignId]: platform
        }));
    } 

    return (
        <div className="grid bg-neutral-100 box-border w-full justify-start gap-4 p-8 grid-cols-1 min-[350px]:grid-cols-[repeat(auto-fill,minmax(350px,1fr))]">
            {media.map((campaign) => (
                <Card
                    key={campaign.id}
                    post_url={campaign.post_url}
                    campaign={campaign}
                    activePlatform={activePlatform}
                    handlePlatformClick={handlePlatformClick}
                />
            ))}
        </div>
    )
}
export default Home;
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCr498wsDjFgOX7uM6qLllnSu8AsDwFHEY",
    authDomain: "advocacy-fab21.firebaseapp.com",
    projectId: "advocacy-fab21",
    storageBucket: "advocacy-fab21.appspot.com",
    messagingSenderId: "386058371365",
    appId: "1:386058371365:web:51fa86346acfe4b87a5acc",
    measurementId: "G-ZBC3SF9LG9"
  };

const app = initializeApp(firebaseConfig);

// Get a Firestore instance
export const db = getFirestore(app);

export default app;